import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WeatherWidget from '../components/weatherWidget'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const Footer = loadable(() => import('../components/footer/footer'))

const WeatherPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'تعرف على أحوال الطقس والمناخ | الشرق للأخبار'
  const seoDescription =
    '.زر موقع الشرق للأخبار واطلع على الحالة الجوية وتنبؤات أحوال الطقس والمناخ في مدن الوطن العربي والعالم'

  const canonicalUrl = `${process.env.GATSBY_BASE_URL}weather/`

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <>
      <Layout location={location} title={siteTitle} lang={'ar'}>
        <h1 className="customH1">{'حالة الطقس'}</h1>
        <SEO
          title={seoTitle}
          lang="ar"
          description={seoDescription}
          canonicalUrl={canonicalUrl}
        />
        <WeatherWidget />
        <Footer />
      </Layout>
    </>
  )
}

export default WeatherPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
