import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  min-height: calc(100vh - 357px);
  border-top: white solid 30px;
  background-color: white;
  @media ${device.s} {
    margin-top: 30px;
    min-height: calc(100vh - 270px);
  }
`
