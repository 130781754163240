import React, { useEffect, useState } from 'react'
import { Spinner } from '../grid/grid.styled'
import { Container, SpinnerContainer } from './weatherWidget.styled'

const WeatherWidget = () => {
  const WEATHER_DATA_SRC = 'https://www.asharqbusiness.com/IFRAME/weather'
  const [showSpinner, setShowSpinner] = useState(true)

  const receiveMessage = event => {
    const iframe = document.getElementById('weather_widget')
    const src = iframe.src || ''

    if (src && src.indexOf(event.origin) === 0) {
      if (event.data.height) {
        iframe.style.height = event.data.height + 10 + 'px'
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', receiveMessage, false)
    }

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [])

  const onIFrameLoad = event => {
    setShowSpinner(false)
    event.target.style.visibility = 'visible'
  }

  return (
    <Container>
      {showSpinner && (
        <SpinnerContainer>
          <Spinner
            inlineProps={{
              height: 30,
              width: 30,
              marginTop: 5
            }}
          />
        </SpinnerContainer>
      )}
      <iframe
        onLoad={onIFrameLoad}
        id="weather_widget"
        width="100%"
        height="100%"
        src={WEATHER_DATA_SRC}
        frameBorder="0"
        style={{
          visibility: 'hidden'
        }}
      />
    </Container>
  )
}

export default WeatherWidget
